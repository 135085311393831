import React, { useState } from 'react';
import { FaCaretDown, FaCaretUp, FaSort } from 'react-icons/fa';
import i18n from 'i18n/config';
import { FiMinusCircle, FiInfo } from 'react-icons/fi';
import { AiOutlineWarning } from 'react-icons/ai';
import { GoVerified } from 'react-icons/go';
import Modal from 'utils/modal';

function prettify(text) {
  const attr = text.split('__').pop();

  return attr
    .split('_')
    .map((str) => str.charAt(0).toUpperCase() + str.substring(1))
    .join(' ');
}

function Attribute({ item, order, onOrderChange, totalSize }) {
  let attrKey = item.searchKey || item.key;
  let icon = <FaSort />;
  if (Array.isArray(item.searchKey)) {
    attrKey = item.searchKey.find(
      (key) => key === order || `-${key}` === order
    );
  }

  if (order === attrKey) {
    icon = <FaCaretUp />;
  } else if (order === `-${attrKey}`) {
    icon = <FaCaretDown />;
  } else if (attrKey === undefined) {
    icon = null;
  }

  if (typeof item.searchKey === 'function') {
    attrKey = item.key;
  }

  let displayItem = item.name;

  if (Array.isArray(item.searchKey)) {
    const options = item.searchKey.map((searchKey) => (
      <option data-attr-key={searchKey} key={searchKey} value={searchKey}>
        {i18n.t(prettify(searchKey))}
      </option>
    ));

    displayItem = (
      <select
        name="status"
        onChange={(e) => onOrderChange(e, item.key)}
        data-attr="status"
      >
        <option value="">{i18n.t('Sort By...')}</option>
        {options}
      </select>
    );
  }

  return (
    <th key={item.key} style={{ width: `${(item.size * 100) / totalSize}%` }}>
      <span>
        <span className="text">{displayItem}</span>
        {item.orderKey && (
          <span
            className="sort"
            data-attr-key={attrKey}
            onClick={onOrderChange}
          >
            {icon}
          </span>
        )}
      </span>
    </th>
  );
}

function Header(props) {
  const [order, setOrdering] = useState('');

  const onOrderChange = (e, id) => {
    const key =
      e.currentTarget.value || e.currentTarget.getAttribute('data-attr-key');
    let ordering;
    if (key === order) {
      ordering = `-${key}`;
    } else {
      ordering = key;
    }
    setOrdering(ordering);
    props.setDropdownActive(id, ordering);
    props.updateQuery('ordering', ordering);
  };

  const totalSize = props.attributes.reduce((acc, cur) => acc + cur.size, 0);

  const nodes = props.attributes.map((item) => (
    <Attribute
      key={item.key}
      item={item}
      totalSize={totalSize}
      order={order}
      onOrderChange={onOrderChange}
    />
  ));

  return (
    <thead>
      <tr>{nodes}</tr>
    </thead>
  );
}

function Row(props) {
  const [modal, setModal] = useState(false);
  const nodes = props.attributes.map((item) => {
    let node;
    if (item.render) {
      node = item.render(props.data[item.key], props.data);
    } else {
      node = props.data[item.key];
    }

    if (
      typeof node === 'object' &&
      node !== null &&
      node.name &&
      node.name.includes('STRIPE')
    ) {
      // const stripeColor = node.name === 'STRIPE OFF' && 'red';
      return (
        <>
          <td
            key={item.key}
            style={{ cursor: 'pointer' }}
            onClick={() => setModal(true)}
          >
            STRIPE
            <FiInfo style={{ marginLeft: 5 }} />
          </td>
          <Modal
            active={modal}
            onCancel={() => setModal(false)}
            content={
              <div>
                <h1>{i18n.t('Stripe Information')}</h1>
                <ul>
                  <li>
                    <h4>
                      {i18n.t('Customer ID:')}{' '}
                      {node.vendor_id || i18n.t('No information')}
                    </h4>
                  </li>
                  <li>
                    <h4>
                      {i18n.t('Subscription ID:')}{' '}
                      {node.sub_id || i18n.t('No information')}
                    </h4>
                  </li>
                </ul>
              </div>
            }
          />
        </>
      );
    }
    if (node === 'customerActive') {
      return (
        <td style={{ color: 'green' }} key={item.key}>
          <GoVerified />
        </td>
      );
    }
    if (node === 'customerInactive') {
      return (
        <td style={{ color: 'orange' }} key={item.key}>
          <AiOutlineWarning />
        </td>
      );
    }
    return <td key={item.key}>{node}</td>;
  });

  return <tr>{nodes}</tr>;
}

function Body(props) {
  const { attributes } = props;
  const nodes = props.results.map((item) => (
    <Row key={item.id} data={item} attributes={attributes} />
  ));

  return <tbody>{nodes}</tbody>;
}

function EmptyResults(props) {
  return (
    <div className="no-results">
      <FiMinusCircle />
      <h3>{i18n.t('No results')}</h3>
    </div>
  );
}

class Table extends React.Component {
  render() {
    return (
      <>
        <table id="data-table">
          <Header
            attributes={this.props.attributes}
            updateQuery={this.props.updateQuery}
            setDropdownActive={this.props.setDropdownActive}
          />
          <Body
            attributes={this.props.attributes}
            results={this.props.results}
          />
        </table>
        {this.props.results.length === 0 && !this.props.loading ? (
          <EmptyResults />
        ) : null}
      </>
    );
  }
}

export default Table;
